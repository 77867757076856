import React from "react";
import illusMaintenance from "../images/illusMaintenance.svg";
import { Header, Footer } from "../components/layout/index";
import { Seo } from "../components/Seo";

const MaintenancePage = () => {
  return (
    <>
      <Seo title="Maintenance" />
      <Header />
      <div className="flex flex-col w-full items-center mt-16 mb-20 px-4 sm:px-6 lg:mt-24 lg:mb-28 lg:px-8">
        <h1 className="text-xl sm:text-3xl tracking-tight font-bold lg:text-4xl text-action-600 text-center mb-8">
          Omnidoc est en cours de maintenance.
        </h1>
        <p className="text-lg sm:text-xl lg:text-2xl text-center mb-8">
          Le service sera rétabli dans quelques instants.
        </p>
        <div className="flex justify-center flex-col">
          <img
            className="py-4 m-0 h-80"
            src={illusMaintenance}
            alt="Omnidoc, plateforme de télé-expertise"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MaintenancePage;
